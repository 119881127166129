const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '/',
    defaultPath: '/dashboard/default',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    API_SERVER: '/api/',
    // API_SERVER: 'http://localhost:5000/api/',
    ONESIGNAL_API_KEY: '8bb4e39c-2843-482d-99b2-ded1559dfa6f',

};

export default config;
