import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid } from '@material-ui/core';

const AdminSection = () => {
    const account = useSelector((state) => state.account);
    // console.log(account)
    let isAdmin = false;
    if (account.user){
        if (account.user.role === 'admin') {
            // console.log("é admin")
            isAdmin = true;
        }
    }

    return (
            <Grid container spacing={3}>
                <Grid item xs>
                    
                </Grid>
                <Grid item xs={6}>

                </Grid>
                <Grid item xs>
                    {
                        isAdmin ? (
                            <Button href="/admin" variant="contained" color="error">
                            Painel Admin
                            </Button>
                        ): null
                    }
                </Grid>
            </Grid>
    )
}

export default AdminSection;