// assets
import { IconBrandInstagram , IconEdit} from '@tabler/icons';

// constant
const icons = {
    IconBrandInstagram: IconBrandInstagram,
    IconEdit: IconEdit
};

//-----------------------|| INSTAGRAM MENU ITEMS ||-----------------------//

export const instagram = {
    id: 'instagram',
    title: 'Instagram',
    type: 'group',
    children: [
        {
            id: 'visualizar-instagram',
            title: 'Contas Cadastradas',
            type: 'item',
            url: '/contas-instagram',
            icon: icons['IconBrandInstagram'],
            breadcrumbs: false
        },
        {
            id: 'casdastro-instagram',
            title: 'Cadastro de Contas',
            type: 'item',
            url: '/cadastro-instagram',
            icon: icons['IconEdit'],
            breadcrumbs: false
        },

    ]
};
