import { Card, CardContent, Grid, Typography,Snackbar, Alert, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    card: {
        background: theme.palette.warning.light,
        marginTop: '16px',
        marginBottom: '16px',
        overflow: 'hidden',
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '200px',
            height: '200px',
            border: '19px solid ',
            borderColor: theme.palette.warning.main,
            borderRadius: '50%',
            top: '65px',
            right: '-150px',
            zIndex: -1
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '200px',
            height: '200px',
            border: '3px solid ',
            borderColor: theme.palette.warning.main,
            borderRadius: '50%',
            top: '145px',
            right: '-70px'
        }
    },
    tagLine: {
        color: theme.palette.grey[900],
        opacity: 0.6
    },
    button: {
        color: theme.palette.grey[800],
        backgroundColor: theme.palette.warning.main,
        textTransform: 'capitalize',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: theme.palette.warning.dark
        }
    }
}));

//-----------------------|| PROFILE MENU - UPGRADE PLAN CARD ||-----------------------//

const UpgradePlanCard = () => {
    const classes = useStyles();
    const account = useSelector((state) => state.account);
    let referCode = ''
    if (account.user) {
        referCode = account.user.referCode
    }
    
    const [open, setOpen] = React.useState(false);

    
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
    return (
        <>

        <Snackbar open={open} autoHideDuration={800} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                Código de referência copiado!
            </Alert>
        </Snackbar>

        <Card className={classes.card}>
            <CardContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="h4">
                            Código de Indicação
                        </Typography>
                    </Grid>
                    <Grid item>

                        <TextField
                            color="error"
                            hiddenLabel
                            fullWidth
                            id="filled-hidden-label-small"
                            defaultValue={"https://app.ganheindicando.com/register?ref="+referCode}
                            variant="filled"
                            onClick={e => {
                                e.target.focus();
                                e.target.select();
                              }}
                        />
                    </Grid>
                    <Grid item>
  
                        <Typography variant="subtitle2" className={classes.tagLine}>
                           Envie para seus amigos e ganhe mais vantagens.
                        </Typography>
          
                    </Grid>

                </Grid>

            </CardContent>
        </Card>
        </>
    );
};

export default UpgradePlanCard;
