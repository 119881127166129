import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));

// My personal pages
const casdastroInstagram = Loadable(lazy(() => import('../views/instagram/cadastro-instagram')));
const editarInstagram = Loadable(lazy(() => import('../views/instagram/editar-instagram')));
const contasInstagram = Loadable(lazy(() => import('../views/instagram/contas-instagram')));

const casdastroTikTok = Loadable(lazy(() => import('../views/tiktok/cadastro-tiktok')));
const editarTikTok = Loadable(lazy(() => import('../views/tiktok/editar-tiktok')));
const contasTikTok = Loadable(lazy(() => import('../views/tiktok/contas-tiktok')));

const solicitarSeguidores = Loadable(lazy(() => import('../views/solicitar/seguidores')));
const solicitarCurtidas = Loadable(lazy(() => import('../views/solicitar/curtidas')));

const admin = Loadable(lazy(() => import('../views/admin')));
const stats = Loadable(lazy(() => import('../views/admin/more-stats')));


//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard/default',

                '/utils/util-typography',
                '/utils/util-color',
                '/utils/util-shadow',
                '/icons/tabler-icons',
                '/icons/material-icons',

                '/sample-page',

                '/cadastro-instagram',
                '/editar-instagram',
                '/contas-instagram',

                '/cadastro-tiktok',
                '/editar-tiktok',
                '/contas-tiktok',

                '/solicitar-seguidores',
                '/solicitar-curtidas',

                '/admin',
                '/admin-stats'
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/dashboard/default" component={DashboardDefault} />

                        <Route path="/utils/util-typography" component={UtilsTypography} />
                        <Route path="/utils/util-color" component={UtilsColor} />
                        <Route path="/utils/util-shadow" component={UtilsShadow} />
                        <Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
                        <Route path="/icons/material-icons" component={UtilsMaterialIcons} />

                        <Route path="/sample-page" component={SamplePage} />

                        <Route path="/solicitar-seguidores" component={solicitarSeguidores} />
                        <Route path="/solicitar-curtidas" component={solicitarCurtidas} />


                        <Route path="/cadastro-instagram" component={casdastroInstagram} />
                        <Route path="/editar-instagram/:id/:user" component={editarInstagram} />
                        <Route path="/contas-instagram" component={contasInstagram} />

                        <Route path="/cadastro-tiktok" component={casdastroTikTok} />
                        <Route path="/editar-tiktok/:id/:user" component={editarTikTok} />
                        <Route path="/contas-tiktok" component={contasTikTok} />
                        <Route path="/admin" component={admin} />
                        <Route path="/admin-stats" component={stats} />
         

                    </AuthGuard>
  
                            

  
                    
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
