// assets
import { IconThumbUp , IconUser} from '@tabler/icons';


//-----------------------|| SOLICITAR MENU ITEMS ||-----------------------//

export const solicitar = {
    id: 'solicitar',
    title: 'Solicitar',
    type: 'group',
    children: [
        {
            id: 'seguidores',
            title: 'Seguidores',
            type: 'item',
            url: '/solicitar-seguidores',
            icon: IconUser,
            breadcrumbs: false
        },
        {
            id: 'curtidas',
            title: 'Curtidas',
            type: 'item',
            url: '/solicitar-curtidas',
            icon: IconThumbUp,
            breadcrumbs: false
        },

    ]
};
