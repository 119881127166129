// assets
import { IconBrandTiktok , IconEdit} from '@tabler/icons';

// constant
const icons = {
    IconBrandTiktok: IconBrandTiktok,
    IconEdit: IconEdit
};

//-----------------------|| INSTAGRAM MENU ITEMS ||-----------------------//

export const tiktok = {
    id: 'tiktok',
    title: 'Tiktok',
    type: 'group',
    children: [
        {
            id: 'visualizar-tiktok',
            title: 'Contas Cadastradas',
            type: 'item',
            url: '/contas-tiktok',
            icon: icons['IconBrandTiktok'],
            breadcrumbs: false
        },
        {
            id: 'cadastro-tiktok',
            title: 'Cadastro de Contas',
            type: 'item',
            url: '/cadastro-tiktok',
            icon: icons['IconEdit'],
            breadcrumbs: false
        },

    ]
};
