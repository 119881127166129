import { dashboard } from './dashboard';

import { instagram } from './instagram';
import { solicitar } from './solicitar';
import { tiktok } from './tiktok';

//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [dashboard, solicitar, tiktok, instagram]
};

export default menuItems;
